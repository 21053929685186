import { isArray, isDef, isNumber, isValidKey } from '@qctsw/utils'
import {
  ARTICLE_FORMAT_CODE,
  ARTICLE_TYPE_CODE,
  CAR_INSURANCE_CODE,
  COMPLAINT_COLLECTIVE_STATUS,
  COMPLAINT_SETUPS,
  COMPLAINT_STATUS,
  COOP_MEDIA_TYPE_CODE,
  DISPOSE_TYPE_CODE,
  ISSUE_TYPE_CODE,
  SCORE,
  USER_TYPE_CODE,
  USER_TYPE_SUFFIX,
} from '../constants'

/**
 * 获取性别文本
 * @param value
 * @returns '男' | '女' | '未知'
 */
export function getSexText(value: number): string {
  if ([1, 2].includes(value))
    return value === 1 ? '男' : '女'
  else
    return '未知'
}

/**
 * 获取性别code
 * @param text
 * @returns 1:男 | 2:女 | 0:未知
 */
export function getSex(text: string): number {
  if (text.match(/男/))
    return 1
  else if (text.match(/女/))
    return 2
  else return 0
}

/**
 * 通过问题Code数组获取问题文本字符串, 忽略诉求
 * @param arr 问题 Code Array
 * @param isFullName 是否问题全称
 * @returns
 */
export function getIssueText(arr: string[] | (keyof typeof ISSUE_TYPE_CODE)[], isFullName = false): string {
  if (!isArray(arr))
    return ''
  const texts: string[] = []
  arr.forEach((v) => {
    if (isValidKey(v, ISSUE_TYPE_CODE) && Number.parseInt(v) <= 3 && Number.parseInt(v) > 0) {
      const issue: string = ISSUE_TYPE_CODE[v]
      if (isFullName)
        texts.push(issue)
      else
        texts.push(issue?.replace('问题', ''))
    }
  })
  return texts.join(' ')
}

/**
 * 通过问题Code获取问题文本
 * @param code ISSUE_TYPE_CODE
 * @returns
 */
export function getIssue(code: number): string {
  let str = ''
  if (isValidKey(code, ISSUE_TYPE_CODE))
    str = ISSUE_TYPE_CODE[code]
  return str
}

export function getComplaintStatusActive(code: number) {
  if (code === 8)
    return 2
  return [0, 1, 2, 3, 4].findIndex(v => v === code)
}

export function getComplaintStatus(code: number | string): string {
  const numberCode = isNumber(code) ? code : Number.parseInt(code)
  if (!isDef(code))
    return ''

  if (numberCode && Number.isNaN(numberCode)) {
    console.error(`getComplaintStatus param is NaN:${code}`)
    return code.toString()
  }
  let str = ''
  if (isValidKey(numberCode, COMPLAINT_STATUS))
    str = COMPLAINT_STATUS[numberCode]

  return str
}

/**
 * 根据集体投诉状态code获取集体投诉状态文本
 * @param code 集体投诉状态code
 * @returns 集体投诉状态文本
 */
export function getComplaintCollectiveStatus(code: number): string {
  let str = ''
  if (isValidKey(code, COMPLAINT_COLLECTIVE_STATUS))
    str = COMPLAINT_COLLECTIVE_STATUS[code]
  return str
}

/**
 * 根据结果类型code获取结果类型文本
 * @param code 结果类型code
 * @returns 结果类型文本
 */
export function getDisposeTypeCode(code: string | number) {
  if (
    Object.keys(DISPOSE_TYPE_CODE).includes(code.toString())
    && isValidKey(code, DISPOSE_TYPE_CODE)
  )
    return DISPOSE_TYPE_CODE[code]
  else
    return DISPOSE_TYPE_CODE[0]
}

/**
 * 根据文章格式code获取文章类型文本
 * @param key
 * @returns
 */
export function getNewsFormat(key: string) {
  if (!key)
    key = '0'
  if (isValidKey(key, ARTICLE_FORMAT_CODE))
    return ARTICLE_FORMAT_CODE[key]

  return ARTICLE_FORMAT_CODE[0]
}

/**
 * 根据文章类型code获取文章类型文本
 * @param key
 * @returns
 */
export function getArticleTypeCode(key: string) {
  if (!key)
    key = '0'
  if (isValidKey(key, ARTICLE_TYPE_CODE))
    return ARTICLE_TYPE_CODE[key]

  return ARTICLE_TYPE_CODE[0]
}

/**
 * 根据合作媒体类型code获取合作媒体类型文本
 * @param key
 * @returns
 */
export function getCoopMediaTypeCode(key: keyof typeof COOP_MEDIA_TYPE_CODE) {
  if (!key)
    key = 0
  if (isValidKey(key, COOP_MEDIA_TYPE_CODE))
    return COOP_MEDIA_TYPE_CODE[key]

  return COOP_MEDIA_TYPE_CODE[0]
}

/**
 * 根据投诉code获取投诉当前步骤index
 * @param code
 * @returns index
 */
export function stepIndex(code: number) {
  let stepIndex = COMPLAINT_SETUPS.findIndex((v) => {
    return v.ids.includes(code)
  })
  if (stepIndex === -1)
    stepIndex = 0
  return stepIndex
}

/**
 * 根据用户类型code获取用户类型文本
 * @param value
 * @param isSuffix
 * @returns
 */
export function getUserTypeCode(value: keyof typeof USER_TYPE_CODE, isSuffix = false) {
  if (Object.keys(USER_TYPE_CODE).includes(value)) {
    return isSuffix
      ? USER_TYPE_CODE[value] + USER_TYPE_SUFFIX[value]
      : USER_TYPE_CODE[value]
  }

  return value
}

/**
 * 获取用户打分文本
 * @param value
 * @returns
 */
export function getScoreText(value: keyof typeof SCORE) {
  if (Object.keys(SCORE).includes(value?.toString()))
    return SCORE[value]

  return value.toString()
}

/**
 * CAR_INSURANCE_CODE
 */
export function getCarInsuranceCodeText(value: keyof typeof CAR_INSURANCE_CODE) {
  const index = CAR_INSURANCE_CODE.findIndex(v => v.value === value)

  if (index === -1)
    return ''
  return CAR_INSURANCE_CODE[index].label
}
